@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Preahvihear&family=Protest+Strike&display=swap');

.head {

    font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";


}
.welcome{
    position: relative;
    right: 58px;
}
.head1 {
    font-size: 72px;
    font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

}

.minds {
    font-size: 70px;
    /* Text color (inside) */
    color: transparent;
    /* Outer line color */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000000;
}


.visit {
    align-items: center;
    background-color: rgb(147, 147, 191);
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    outline: 0;
    border: 0;
    padding: 5px 12px;
}



.visit:hover:before {
    background-color: #15b4cd;
    width: 3rem;
}

.inputGroup {
    font-family: 'Segoe UI', sans-serif;
    margin: 2em 0 1em 0;
    width: 280px;
    position: relative;
}

.inputGroup input {
    font-size: 100%;
    padding: 0.8em;
    outline: none;
    border: 2px solid rgb(200, 200, 200);
    background-color: transparent;
    border-radius: 12px;
    width: 100%;
}

.Login {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
}

.Login {
    --primary-color: #111;
    --hovered-color: #F5558D;
    position: relative;
    display: flex;
    font-weight: 600;
    font-size: 20px;
    gap: 0.5rem;
    align-items: center;
}

.Login p {
    margin: 0;
    position: relative;
    font-size: 20px;
    color: var(--primary-color);
}

.Login::after {
    position: absolute;
    content: "";
    width: 0;
    left: 0;
    bottom: -7px;
    background: var(--hovered-color);
    height: 2px;
    transition: 0.3s ease-out;
}

.Login p::before {
    position: absolute;
    content: "Login Now";
    width: 0%;
    inset: 0;
    color: var(--hovered-color);
    overflow: hidden;
    transition: 0.3s ease-out;
}

.Login:hover::after {
    width: 100%;
}

.Login:hover p::before {
    width: 100%;
}

.Login:hover svg {
    transform: translateX(4px);
    color: var(--hovered-color);
}

.Login svg {
    color: var(--primary-color);
    transition: 0.2s;
    position: relative;
    width: 15px;
    transition-delay: 0.2s;
}

.login-form {
    width: 55%;
    height: 400px;

}

.login-text {
    width: 100%;
    height: 430px;
}
.media-icons{

   display:flex;
   flex-direction: column;
   justify-content: center;
   align-items:space-evenly ;
}

@media only screen and (max-width: 768px) {
    .login-form {
        width: 88%;
        height: 400px;

    }

    .login-text {
        width: 100%;
        height: 430px;
    }

    .head1 {
        font-size: 65px;
    }

    .visit_site {
        display: flex;
        justify-content: center;
    }

    .minds {
        font-size: 60px;
    }
    .welcome{
        position: relative;
        right: 0px;
    }
    .media-icons{

        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items:space-evenly ;
     }
}

@media only screen and (max-width: 450px) {
    .head1 {
        font-size: 50px;
    }

    .minds {
        font-size: 42px;
    }

    .welcome{
        position: relative;
        right: 0px;
    }
    .media-icons{

        display:flex;
        flex-direction: row;

        justify-content: center;
        align-items:space-evenly ;
     }
     .styled-text{
        position: relative;
        bottom: 5px;
     }

}