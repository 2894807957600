.nav-pills .nav-link.active {
    color: white !important;
    background-color: rgb(30,74,232) !important;
    border: 1px solid rgb(30,74,232)!important;
    border-radius: 4px;
    transform: scale(1.02);
}
.nav-pills .nav-link{
    background-color: rgb(244,244,244) !important;
    border: 2px solid white  !important;
    margin-left: 20px;
    box-shadow: 1px 3px 3px rgb(135, 134, 134);
}
.sideinleft {
    
}