@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.heading {
    /* background-color: red; */
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.fanal-text {
    font-family: "Open Sans", sans-serif;
}

.text-total {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.text-total1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
}

.speedometer-container {
    width: 100%;
}

.Candidate_reg {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;

}

.poppins {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #474E77;
}

.raleway {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}
